.task_page__online-tabs {
  position: absolute;
  width: 100%;
  padding: 16px;
  z-index: 1;
}
.task_page__online-tabs-sticky {
  position: sticky;
  top: 0;
  background-color: #67809F;
}
.theme-light .task_page__online-tab.ui-button.ui-button-mode-text {
  color: #FFFFFF;
  border-bottom: 2px solid rgba(240, 240, 240, 0.2);
}
.theme-light .task_page__online-tab.ui-button.ui-button-mode-text:not([disabled]):active,
.theme-light .task_page__online-tab.ui-button.ui-button-mode-text:not([disabled]):hover {
  background-color: rgba(240, 240, 240, 0.2);
}
.theme-light .task_page__online-tab.ui-button.ui-button-mode-text:not([disabled]):hover {
  border-bottom: 2px solid rgba(240, 240, 240, 0.5);
}
.theme-light .task_page__online-tab.ui-button.ui-button-mode-text:not([disabled]):active {
  border-bottom: 2px solid #f0f0f0;
}
.theme-light .task_page__online-tab.ui-button.ui-button-mode-text.ui-button-active:not([disabled]),
.theme-light .task_page__online-tab.ui-button.ui-button-mode-text:not([disabled]):active {
  color: #FFFFFF;
  background-color: transparent;
  border-bottom: 2px solid #f0f0f0;
}
