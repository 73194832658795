.text-xxl {
  font-size: 32px;
  line-height: 40px;
}
.text-xl {
  font-size: 24px;
  line-height: 32px;
}
.text-l {
  font-size: 18px;
  line-height: 28px;
}
.text-m {
  font-size: 16px;
  line-height: 24px;
}
.text-s {
  font-size: 14px;
  line-height: 20px;
}
.text-xs {
  font-size: 12px;
  line-height: 16px;
}
.text-xxs {
  font-size: 10px;
  line-height: 12px;
}
.smt-register-form {
  border: 1px solid #d5dde6;
  border-radius: 8px;
  text-align: center;
  padding: 32px;
  width: 460px;
  max-width: 460px;
  margin: 72px auto 0;
  box-sizing: border-box;
}
.smt-register-form__register-btn {
  margin-top: 32px;
}
.smt-register-form__has-errors-warning {
  color: #F94C4F;
  padding: 16px;
  justify-content: center;
  align-items: center;
}
.smt-register-form-show-required .sf-register-form-required.sf-register-form-has-error > .sf-register-form__label .ui-input-wrapper,
.smt-register-form-show-required .sf-register-form-required.sf-register-form-has-error > .sf-register-form__label .ui-input-wrapper,
.smt-register-form-show-required .sf-register-form-required.sf-register-form__string-empty-true > .sf-register-form__label .ui-input-wrapper,
.smt-register-form-show-required .sf-register-form-required.sf-register-form__string-empty-true > .sf-register-form__label .ui-input-wrapper {
  box-shadow: 0px 0px 1px 1px #F94C4F;
}
.smt-register-form-show-required .sf-register-form-required.sf-register-form-has-error > .sf-register-form__label > .sf-register-form__meta > .sf-register-form__title > .sf-register-form__title-label,
.smt-register-form-show-required .sf-register-form-required.sf-register-form-has-error > .sf-register-form__label > .sf-register-form__meta > .sf-register-form__title > .sf-register-form__title-label,
.smt-register-form-show-required .sf-register-form-required.sf-register-form__string-empty-true > .sf-register-form__label > .sf-register-form__meta > .sf-register-form__title > .sf-register-form__title-label,
.smt-register-form-show-required .sf-register-form-required.sf-register-form__string-empty-true > .sf-register-form__label > .sf-register-form__meta > .sf-register-form__title > .sf-register-form__title-label {
  color: #F94C4F;
}
