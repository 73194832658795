.user-confirmation-modal {
  background: rgba(35, 41, 67, 0.5);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}
.user-confirmation-modal__wrapper {
  border-radius: 24px;
  background: #FFFFFF;
  position: fixed;
  max-width: 460px;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 32px 0;
}
@media (max-width: 760px) {
  .user-confirmation-modal__wrapper {
    max-width: none;
    bottom: 0;
    border-radius: 24px 24px 0 0;
  }
}
.user-confirmation-modal__title {
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  color: #232943;
  text-align: center;
  margin: 8px auto 16px auto;
}
.user-confirmation-modal__title:last-child {
  margin-bottom: 8px;
}
.user-confirmation-modal__item {
  padding: 4px 32px;
  margin: 8px 0;
}
.user-confirmation-modal__username {
  padding: 16px 32px;
  margin: 8px 0;
}
.user-confirmation-modal__username-wrapper {
  display: flex;
  min-height: 48px;
  padding: 0 16px;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: #1B3C68;
  font-size: 24px;
  line-height: 37.22px;
}
.user-confirmation-modal__caption {
  color: #69798C;
  text-align: center;
  margin: 8px auto 16px auto;
  font-size: 18px;
  line-height: 24px;
}
.user-confirmation-modal__caption:last-child {
  margin-bottom: 8px;
}
.user-confirmation-modal__descr {
  color: #69798C;
  font-size: 14px;
  line-height: 20.04px;
  text-align: center;
  margin: 8px auto 10px auto;
}
.user-confirmation-modal__button {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #4B8CEE;
  cursor: pointer;
  font-family: inherit;
  margin: 8px auto 16px auto;
  border-radius: 16px;
  border: 1px solid #4B8CEE;
  min-height: 40px;
  padding: 0 28px;
  width: 100%;
  font-size: 18px;
  line-height: 25.31px;
  outline: none;
  background: transparent;
}
.user-confirmation-modal__button:last-child {
  margin-bottom: 8px;
}
.user-confirmation-modal__button-main {
  color: #FFFFFF;
  background: #4B8CEE;
  min-height: 56px;
}
.user-confirmation-modal__button-small {
  width: auto;
  border-radius: 20px;
}
