.programming-answer__io-box,
.programming-answer__io-cell {
  flex: 1;
}
.programming-answer__io-row {
  margin-bottom: 8px;
}
.programming-answer__io-pre {
  margin: 0;
  font-size: 16px;
}
.programming-answer__io-head {
  font-size: 16px;
}
.programming-answer__io-cell {
  background-color: #F5F5F5;
  padding: 12px 16px;
  font-family: monospace;
}
.programming-answer__io-cell:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  margin-right: 2px;
}
.programming-answer__io-cell:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
.programming-answer__limits {
  line-height: 30px;
}
.programming-answer__limits-head {
  margin: 0;
  font-size: 16px;
  font-weight: 700;
}
.programming-answer__limits-list {
  margin: 0;
  padding: 0;
}
.programming-answer__limits-item {
  margin: 0;
  font-size: 16px;
  list-style-type: none;
}
.programming-answer__hidden-part {
  background-color: #EEEEEE;
  border-radius: 0 0 8px 8px;
}
.programming-answer__hidden-part-text {
  margin: 30px 0;
  color: #828282;
  font-weight: normal;
  font-size: 16px;
}
.programming-answer__hidden-part-sharp {
  border-radius: 0;
}
.programming-answer__editor {
  border: 1px solid #d5dde7;
  border-radius: 8px;
  padding: 12px 1px;
  background-color: #eaf3ff;
  font-size: 14px;
  min-height: 160px;
}
.programming-answer__editor.smt-code-editor {
  font-family: "PT Root UI", Arial, sans-serif;
}
.programming-answer__editor-code {
  max-height: 70vh;
  overflow: scroll;
}
.programming-answer__editor-code > div {
  overflow: auto !important;
}
.programming-answer__editor-focus-within-textarea {
  outline: -webkit-focus-ring-color auto 1px;
  background-color: #F5F5F5;
}
.programming-answer__editor-textarea,
.programming-answer__editor-pre {
  outline: none;
  min-width: 53px;
}
.programming-answer__editor-pre .line-num {
  width: 0;
  position: relative;
}
.programming-answer__editor-pre .line-num:before {
  content: attr(data-num);
  color: grey;
  text-align: right;
  width: 32px;
  display: inline-block;
  margin-left: -48px;
  position: absolute;
}
.programming-answer__editor-focus-within-textarea .programming-answer__editor-textarea,
.programming-answer__editor-focus-within-textarea .programming-answer__editor-pre {
  background-color: #FFFFFF;
}
.programming-answer__editor-clickable {
  cursor: pointer;
}
.programming-answer__editor-head {
  align-items: center;
  padding: 0px 12px;
}
.programming-answer__editor-head > b {
  font-size: 16px;
}
.programming-answer__editor-head .dropdown-select .select-option {
  font-size: 14px;
}
.programming-answer__editor-status-ok {
  background-color: #F1FAEA;
}
.programming-answer__editor-status-notok {
  background-color: #FFF0F2;
  cursor: pointer;
}
.programming-answer__editor-status-partly {
  background-color: #FEF5D9;
  cursor: pointer;
}
.programming-answer__verdicts-solution .programming-answer__editor-pre.programming-answer__editor-clickable {
  pointer-events: initial !important;
}
.programming-answer__linenum {
  width: 0px;
  position: absolute;
}
.programming-answer__linenum:before {
  content: attr(data-num);
  color: grey;
  text-align: right;
  width: 32px;
  display: inline-block;
  margin-left: -48px;
}
.programming-answer__file {
  padding: 4px 0px;
}
.programming-answer__file-input {
  position: absolute;
  left: -100000px;
}
.programming-answer__verdicts-card {
  margin-bottom: 8px;
  border-radius: 8px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2);
  max-height: 80vh;
}
.programming-answer__verdicts-card-ok {
  background-color: #F1FAEA;
}
.programming-answer__verdicts-card-notok {
  background-color: #FFF0F2;
}
.programming-answer__verdicts-card-partly {
  background-color: #FEF5D9;
}
.programming-answer__verdicts-card-annulled {
  background-color: inherit;
}
.programming-answer__verdicts-card-clickable {
  cursor: pointer;
}
.programming-answer__verdicts-toggle-smt {
  margin-top: 8px;
  padding: 16px 24px;
  color: #333333;
  background-color: #F5F5F5;
  box-shadow: none;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
}
.programming-answer__verdicts-toggle-smt-wrong {
  background-color: #FFF1F2;
}
.programming-answer__verdicts-toggle-smt-wrong:hover {
  background-color: #ffd2d6;
}
.programming-answer__verdicts-toggle-smt-partly {
  background-color: #FFF5D9;
}
.programming-answer__verdicts-toggle-smt-partly:hover {
  background-color: #ffedba;
}
.programming-answer__verdicts-toggle-smt:not(.programming-answer__verdicts-toggle-smt-partly).programming-answer__verdicts-toggle-smt:not(.programming-answer__verdicts-toggle-smt-wrong).programming-answer__verdicts-toggle-smt:hover {
  background-color: #e6e6e6;
}
.programming-answer__verdicts-tm {
  color: rgba(0, 0, 0, 0.5);
}
.programming-answer__verdicts-head {
  font-size: 14px;
  align-items: center;
  padding: 16px 24px;
}
.programming-answer__verdicts-head > b {
  font-size: 16px;
}
.programming-answer__verdicts-code {
  overflow: auto;
  scrollbar-color: rgba(169, 169, 169, 0.31) rgba(218, 218, 218, 0.25);
  scrollbar-width: thin;
  font-size: 16px;
  line-height: 24px;
}
.programming-answer__verdicts-code::-webkit-scrollbar {
  height: 5px;
  width: 5px;
  border-radius: 10px;
}
.programming-answer__verdicts-code::-webkit-scrollbar-track {
  background: rgba(218, 218, 218, 0.25);
}
.programming-answer__verdicts-code::-webkit-scrollbar-thumb {
  background: rgba(169, 169, 169, 0.31);
  border-radius: 5px;
}
.programming-answer__verdicts-code::-webkit-scrollbar-thumb:hover {
  background: rgba(218, 218, 218, 0.45);
}
.programming-answer__verdicts-error {
  padding: 0 24px 16px;
  font-size: 13px;
  align-items: baseline;
}
.programming-answer__verdicts-score {
  font-weight: bold;
  font-size: 14px;
}
.programming-answer__verdicts-last-score {
  font-weight: normal;
}
.programming-answer__verdicts-last-score-wrong,
.programming-answer__verdicts-last-score-pe {
  color: #FF6759;
}
.programming-answer__verdicts-last-score-partly {
  color: #ED9526;
}
.programming-answer__verdicts-last-score-ok {
  color: #1F8B24;
}
.programming-answer__verdicts-string {
  display: inline-flex;
  margin-left: 8px;
}
.programming-answer__verdicts-review-message {
  padding: 8px 24px;
  white-space: break-spaces;
  font-family: monospace;
  font-size: 12px;
  line-height: 14px;
  margin: 0;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.programming-answer__verdicts-code .programming-answer__verdicts-review {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  margin: 0;
}
.programming-answer__verdicts-code .programming-answer__verdicts-review-panel {
  background-color: #36373D;
  color: #FFFFFF;
}
.programming-answer__verdicts-code .programming-answer__verdicts-review-panel:only-child {
  border-radius: inherit;
}
.programming-answer__verdicts-code .programming-answer__verdicts-review-result {
  padding: 8px 24px;
  font-family: "PT Root UI", Arial, sans-serif;
  font-weight: 500;
  line-height: 24px;
}
.programming-answer__verdicts-code .programming-answer__verdicts-review-result-sub {
  font-weight: normal;
  font-family: 'Source Code Pro', monospace;
  padding-left: 32px;
}
.programming-answer__templates-selector.dropdown-select {
  width: auto;
  min-width: 120px;
  padding: 0px;
}
.programming-answer__templates-selector .dropdown-select__selected-container {
  align-items: center;
}
.programming-answer__templates-selector .dropdown-select__popover-container {
  padding: 4px;
}
.programming-answer__templates-selector .dropdown-select__select-item {
  padding: 8px;
}
.programming-answer__solution {
  /*no-& {
        min-height: 36px;
    }*/
}
.programming-answer .solution_to_show_popup__solutions {
  padding: 0px;
}
.solution_to_show_popup__solutions .programming-answer__solution {
  font-size: 16px;
  margin: 8px;
}
.programming-answer-examples {
  margin-bottom: 32px;
}
.programming-answer-examples__title {
  font-weight: bold;
  margin-top: 24px;
  font-size: 16px;
}
.programming-answer-examples__header {
  color: #36363E;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
}
.programming-answer-examples__header-title {
  width: 50%;
  padding: 29px 24px 13px;
  margin: 0;
}
.programming-answer-examples__row {
  background: #F5F5F5;
  border-radius: 8px;
  margin-bottom: 16px;
  font-size: 18px;
  line-height: 24px;
  word-break: break-word;
}
.programming-answer-examples__row > .programming-answer-examples__row-part {
  width: 50%;
  padding: 18px 24px;
  font-family: 'Source Code Pro', monospace;
}
.programming-answer-examples__row > .programming-answer-examples__row-part + .programming-answer-examples__row-part {
  border-left: 1px solid #fff;
}
.programming-answer-examples__row-title {
  font-family: "PT Root UI", sans-serif;
  display: none;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  align-items: center;
  color: #36363E;
  margin: 0;
}
.programming-answer-examples-content {
  white-space: pre-wrap;
  margin: 0;
}
@media (max-width: 760px) {
  .programming-answer-examples__header {
    display: none;
  }
  .programming-answer-examples__row + .programming-answer-examples__row {
    margin-top: 16px;
  }
  .programming-answer-examples__row {
    flex-wrap: wrap;
    font-size: 16px;
    line-height: 20px;
    padding: 0;
    margin: 0;
  }
  .programming-answer-examples__row > .programming-answer-examples__row-part {
    width: 100%;
    padding: 10px 16px;
  }
  .programming-answer-examples__row > .programming-answer-examples__row-part + .programming-answer-examples__row-part {
    border-left: none;
    border-top: 1px solid #fff;
  }
  .programming-answer-examples__row-header {
    display: block;
  }
  .programming-answer-examples__row + .programming-answer-examples__row {
    margin-top: 16px;
  }
  .programming-answer-examples__row-title {
    display: block;
  }
}
.programming-answer__copy-panel {
  background-color: white;
  width: 100%;
  height: 50px;
  padding: 12px 21px;
  border-top: 1px solid #E1DFE6;
}
.programming-answer__copy-panel-round {
  border-radius: 0 0 8px 8px;
}
.programming-answer__verdicts-code .programming-answer__copy-panel .programming-answer__copy-panel-icon {
  margin-right: 30px;
  cursor: pointer;
  width: 24px;
}
