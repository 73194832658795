.prize_type_ordinary {
  padding: 30px 45px;
  box-shadow: 0 2px 16px rgba(68, 90, 118, 0.25);
  border-radius: 24px;
  margin-bottom: 20px;
}
.prize_type_ordinary__title {
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 22px;
}
.prize_type_ordinary__description {
  font-size: 18px;
  line-height: 26px;
}
.prize_type_ordinary__link {
  display: inline-block;
  box-sizing: border-box;
  height: 39px;
  background: #4B8CEE;
  border-radius: 24px;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: #FFFFFF;
  padding: 6px 24px;
  text-decoration: none;
}
@media (max-width: 680px) {
  .prize_type_ordinary {
    padding: 24px;
  }
  .prize_type_ordinary__title {
    font-size: 20px;
    line-height: 26px;
  }
  .prize_type_ordinary__description {
    font-size: 16px;
    line-height: 26px;
  }
}
