.content-description {
  margin: 0 auto;
  width: 720px;
}
.content-description__inner-video {
  position: relative;
  margin-top: 10px;
  margin-bottom: 35px;
  overflow: hidden;
  border-radius: 10px;
  cursor: pointer;
  aspect-ratio: 1.77777778;
  max-height: 226px;
  width: 100%;
}
.content-description__inner-video [data-video] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
@media (max-width: 760px) {
  .content-description {
    width: calc(100% - 32px);
    max-height: initial;
    padding-left: 16px;
    padding-right: 16px;
  }
}
