.text-xxl {
  font-size: 32px;
  line-height: 40px;
}
.text-xl {
  font-size: 24px;
  line-height: 32px;
}
.text-l {
  font-size: 18px;
  line-height: 28px;
}
.text-m {
  font-size: 16px;
  line-height: 24px;
}
.text-s {
  font-size: 14px;
  line-height: 20px;
}
.text-xs {
  font-size: 12px;
  line-height: 16px;
}
.text-xxs {
  font-size: 10px;
  line-height: 12px;
}
.smt-login-page .smt-login-user-form {
  border: 1px solid #d5dde6;
  border-radius: 8px;
  text-align: center;
  padding: 32px;
  width: 460px;
  max-width: 460px;
  margin: 72px auto 0;
  box-sizing: border-box;
}
.smt-login-page .smt-login-user-form__form-title {
  line-height: 26px;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.02em;
  color: #232943;
  margin-bottom: 32px;
}
.smt-login-page .smt-login-user-form__register-btn {
  margin-top: 32px;
}
.smt-login-page .smt-login-user-form__has-errors-warning {
  color: #F94C4F;
  padding: 16px;
  justify-content: center;
  align-items: center;
}
.smt-login-page .smt-login-user-form-show-required .ui-schema-user-form-required.ui-schema-user-form-has-error > .ui-schema-user-form__label .ui-input-wrapper,
.smt-login-page .smt-login-user-form-show-required .ui-schema-user-form-required.ui-schema-user-form__string-empty-true > .ui-schema-user-form__label .ui-input-wrapper {
  box-shadow: 0 0 1px 1px #F94C4F;
}
.smt-login-page .smt-login-user-form-show-required .ui-schema-user-form-required.ui-schema-user-form-has-error > .ui-schema-user-form__label > .ui-schema-user-form__meta > .ui-schema-user-form__title > .ui-schema-user-form__title-label,
.smt-login-page .smt-login-user-form-show-required .ui-schema-user-form-required.ui-schema-user-form__string-empty-true > .ui-schema-user-form__label > .ui-schema-user-form__meta > .ui-schema-user-form__title > .ui-schema-user-form__title-label {
  color: #F94C4F;
}
@media (max-width: 761px) {
  .smt-login-page .smt-login-user-form {
    width: 96%;
    padding: 16px 4%;
  }
  .smt-login-page .smt-login-user-form__form-title {
    line-height: 24px;
    font-size: 16px;
    margin-bottom: 8px;
  }
}
