.text-xxl {
  font-size: 32px;
  line-height: 40px;
}
.text-xl {
  font-size: 24px;
  line-height: 32px;
}
.text-l {
  font-size: 18px;
  line-height: 28px;
}
.text-m {
  font-size: 16px;
  line-height: 24px;
}
.text-s {
  font-size: 14px;
  line-height: 20px;
}
.text-xs {
  font-size: 12px;
  line-height: 16px;
}
.text-xxs {
  font-size: 10px;
  line-height: 12px;
}
.ui-schema-auth-form-deep-1 {
  margin-top: 24px;
}
.ui-schema-auth-form-deep-2 {
  margin-bottom: 16px;
}
.ui-schema-auth-form .react-datepicker-popper {
  z-index: 2;
}
.ui-schema-auth-form__enum-list {
  list-style-type: none;
  margin: -8px 0 0;
  padding: 0;
  text-align: left;
}
.ui-schema-auth-form__enum-item {
  padding: 8px 4px;
  display: flex;
  align-items: baseline;
  cursor: pointer;
}
.ui-schema-auth-form__enum-input-radio {
  display: inline-block;
  min-width: 16px;
  width: 16px;
  min-height: 16px;
  height: 16px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 0px 0px 1px #808080;
}
.ui-schema-auth-form__enum-input-radio-checked-true {
  background-color: #005FFF;
  box-shadow: inset 0px 0px 0px 2px white, 0px 0px 0px 1px #005FFF;
}
.ui-schema-auth-form__enum-input-label {
  margin-left: 16px;
}
.ui-schema-auth-form__string .ui-date-time__input-wrapper,
.ui-schema-auth-form__string-input .ui-input-wrapper {
  min-height: 64px;
  background: #EEF1F5;
  border-radius: 4px;
  border: none;
  padding: 24px 16px 12px;
  line-height: 24px;
  font-size: 16px;
  letter-spacing: 0.02em;
  width: 100%;
  box-sizing: border-box;
}
.ui-schema-auth-form__string .ui-input-wrapper {
  box-shadow: 0 0 0 0 transparent;
  transition: box-shadow 0.3s ease-in-out;
}
.ui-schema-auth-form__string .ui-input-wrapper-mode-wrong {
  border: 1px solid;
  border-color: #F94C4F;
}
.ui-schema-auth-form__string:focus-within .ui-input-wrapper {
  background: #FFFFFF;
  box-shadow: 0 0 0 2px #255EC5;
  transition: box-shadow 0.3s ease-in-out;
}
.ui-schema-auth-form__string:focus-within .ui-input-wrapper-mode-wrong {
  box-shadow: 0 0 0 0 transparent;
}
.ui-schema-auth-form__string-has-no-value:not(:focus-within) .ui-date-time__icon-wrapper,
.ui-schema-auth-form__string-has-no-value:not(:focus-within) .ui-date-time__input {
  opacity: 0;
}
.ui-schema-auth-form__string .text-s > .ui-date-time__input-type-date {
  max-width: unset;
  width: 100%;
  padding: 0;
  margin: 0;
}
.ui-schema-auth-form__string .text-s > .ui-textinput__input {
  width: 100%;
  padding: 0;
}
.ui-schema-auth-form__meta {
  width: auto;
}
.ui-schema-auth-form__meta .ui-schema-auth-form__title-label > p {
  display: inline;
  margin: 0;
  padding: 0;
}
.ui-schema-auth-form__meta .ui-schema-auth-form__title {
  display: inline-flex;
  font-weight: normal;
  padding-top: 8px;
  margin-bottom: 4px;
  user-select: none;
}
.ui-schema-auth-form__string .ui-schema-auth-form__meta .ui-schema-auth-form__title {
  color: #4D4D4D;
  position: absolute;
  margin-top: 16px;
  margin-left: 16px;
  z-index: 1;
  transition: margin-top 0.1s, font-size 0.1s, color 0.1s;
}
.ui-schema-auth-form__string-has-value .ui-schema-auth-form__meta .ui-schema-auth-form__title,
.ui-schema-auth-form__string:focus-within .ui-schema-auth-form__meta .ui-schema-auth-form__title {
  font-size: 12px;
  position: absolute;
  margin-top: 4px;
  color: #999999;
  transition: margin-top 0.1s, font-size 0.1s, color 0.1s;
}
.ui-schema-auth-form__string .ui-schema-auth-form__meta {
  height: 0;
}
.ui-schema-auth-form__meta .marked-link {
  color: #2979FF;
  text-decoration: none;
}
.ui-schema-auth-form__meta .marked-link:hover {
  text-decoration: underline;
  cursor: pointer;
}
.ui-schema-auth-form__meta .marked-link:active {
  color: #2979FF;
}
.ui-schema-auth-form__meta .marked-link:visited {
  color: #0045FF;
}
.ui-schema-auth-form__boolean .ui-schema-auth-form__meta {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
}
.ui-schema-auth-form__boolean .ui-schema-auth-form__meta .ui-schema-auth-form__title {
  text-align: left;
}
.ui-schema-auth-form__boolean .ui-checkbox {
  padding-right: 4px;
}
.ui-schema-auth-form__boolean .ui-checkbox.ui-schema-auth-form__checkbox > .ui-checkbox__input:not(:checked) {
  border-color: #CCCCCC;
}
.ui-schema-auth-form__boolean .ui-checkbox__input {
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
  max-width: 20px;
  max-height: 20px;
  border-radius: 2px;
}
.ui-schema-auth-form__boolean .ui-checkbox__input:checked:after {
  width: 6px;
  height: 12px;
  border-color: #FFFFFF;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  left: 4px;
  top: -1px;
  box-sizing: content-box;
}
.ui-schema-auth-form__boolean .ui-checkbox__input:checked {
  background-color: #999999;
  border-color: transparent;
}
.ui-schema-auth-form__boolean .ui-schema-auth-form__title {
  margin: 0;
  padding: 0;
}
.ui-schema-auth-form-required.ui-schema-auth-form__boolean-has-errors > .ui-schema-auth-form__meta > .ui-checkbox.ui-schema-auth-form__checkbox > .ui-checkbox__input:not(:checked) {
  border: 1px solid #F94C4F;
}
.ui-schema-auth-form__country-select {
  width: 100%;
  min-height: 64px;
  display: block;
  z-index: 1;
  -webkit-appearance: none;
  padding-left: 16px;
  padding-top: 24px;
}
.ui-schema-auth-form__string .ui-input-wrapper.ui-input-wrapper-country {
  padding: 0;
  flex-direction: row;
}
.ui-schema-auth-form__string .ui-input-wrapper.ui-input-wrapper-country:after {
  content: "▾";
  display: inline-block;
  color: #4D4D4D;
  top: -10px;
  left: -32px;
  z-index: 1;
  width: 0;
  height: 0;
  pointer-events: none;
  box-sizing: border-box;
  float: right;
  position: relative;
}
.ui-schema-auth-form__error {
  padding: 8px 16px;
  color: #F94C4F;
}
