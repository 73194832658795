.b-app-modal {
  gap: 8px;
}
.b-app-modal__body > .box {
  width: 100%;
}
.theme-light .b-app-modal__body {
  color: #1A1A1A;
}
.b-app-modal__button-container {
  flex-grow: 1;
  gap: 8px;
}
