.smt-auth-registration-panel {
  flex-grow: 1;
  align-items: center;
  justify-content: center;
}
.smt-auth-registration-panel__back,
.smt-auth-registration-panel__success-message {
  margin-top: 24px;
}
.smt-auth-registration-panel__success-message {
  text-align: center;
  font-weight: 500;
}
.smt-auth-registration-panel__title {
  margin-top: 48px;
  font-weight: 700;
  text-align: center;
}
.smt-auth-registration-panel__wrapper {
  justify-content: center;
  align-items: center;
  width: 360px;
  min-width: 360px;
  max-width: 360px;
}
