.task-close__header .ui-modal__close-btn {
  background-color: transparent;
}
.task-close__header .ui-text-control {
  padding-left: 8px;
}
.task-close__body {
  display: flex;
  align-items: center;
}
.task-close__footer.ui-modal__footer {
  padding-left: 16px;
  padding-right: 16px;
}
.task-close__footer .ui-button__content {
  font-weight: 500;
  line-height: 20px;
}
.task-close-status-not_saved .task-close__footer-confirm {
  background-color: #FD9601;
  color: #FFFFFF;
}
.task-close-status-not_saved .task-close__footer-confirm:not([disabled]):hover {
  background-color: #F28001;
}
