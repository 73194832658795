.oversize-error-popup {
  max-width: 460px;
  width: 90%;
  background-color: #FFFFFF;
  border-radius: 24px;
  text-align: center;
  padding: 44px 32px;
  box-sizing: border-box;
}
.oversize-error-popup__overlay {
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.25);
  z-index: 500;
}
.oversize-error-popup__title {
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  color: #232943;
  margin: 0 0 16px;
}
.oversize-error-popup__description {
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #69798C;
  margin: 0 0 40px;
}
.oversize-error-popup__button.ui-button.ui-button-size-l.ui-button-form-soft {
  width: 100%;
  border-radius: 16px;
  background-color: #4B8CEE;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
}
