@font-face {
  font-family: "PT Root UI";
  font-weight: 700;
  font-style: normal;
  src: local('PT Root UI Bold'), local('PT-Root-UI-Bold'), local('PTRootUI-Bold'), url('../node_modules/@sirius/ui-lib/src/styles/fonts/PT-Root-UI_Bold.woff2') format('woff2'), url('../node_modules/@sirius/ui-lib/src/styles/fonts/PT-Root-UI_Bold.woff') format('woff');
}
@font-face {
  font-family: "PT Root UI";
  font-weight: 500;
  font-style: normal;
  src: local('PT Root UI Medium'), local('PT-Root-UI-Medium'), local('PTRootUI-Medium'), url('../node_modules/@sirius/ui-lib/src/styles/fonts/PT-Root-UI_Medium.woff2') format('woff2'), url('../node_modules/@sirius/ui-lib/src/styles/fonts/PT-Root-UI_Medium.woff') format('woff');
}
@font-face {
  font-family: "PT Root UI";
  font-weight: 400;
  font-style: normal;
  src: local('PT Root UI'), local('PT-Root-UI'), local('PTRootUI'), url('../node_modules/@sirius/ui-lib/src/styles/fonts/PT-Root-UI_Regular.woff2') format('woff2'), url('../node_modules/@sirius/ui-lib/src/styles/fonts/PT-Root-UI_Regular.woff') format('woff');
}
/* Generated with js2less.js.
Original file: src/styles/values/text-sizes.json */
.text-xxl {
  font-size: 32px;
  line-height: 40px;
}
.text-xl {
  font-size: 24px;
  line-height: 32px;
}
.text-l {
  font-size: 18px;
  line-height: 28px;
}
.text-m {
  font-size: 16px;
  line-height: 24px;
}
.text-s {
  font-size: 14px;
  line-height: 20px;
}
.text-xs {
  font-size: 12px;
  line-height: 16px;
}
.text-xxs {
  font-size: 10px;
  line-height: 12px;
}
/* Generated with js2less.js.
Original file: src/styles/values/text-weight.json */
.text-thin {
  font-weight: 300;
}
.text-bold {
  font-weight: 500;
}
.text-extra-bold {
  font-weight: 700;
}
.task_page__bg {
  background: #67809F 50% 100% / cover no-repeat;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: block;
  content: " ";
}
.task_page > .circular-progress circle {
  stroke: #ffffff;
}
.task_page__show_right_answer .task_selector__answers_overlay {
  display: block;
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #232943;
  opacity: 0.4;
  z-index: 5;
}
.task_page__show_right_answer .task_selector__score {
  color: #a4a7af;
  cursor: default;
  user-select: none;
}
.task_page_close {
  position: fixed;
  right: 4px;
  top: 4px;
  padding: 9px;
  cursor: pointer;
  border-radius: 50%;
  z-index: 2;
}
.task_page_close svg {
  display: block;
}
.task_page_close:hover {
  background: rgba(255, 255, 255, 0.2);
}
@media (max-width: 768px) {
  .task_page_close {
    position: absolute;
  }
}
.task_solution--type-match--verdict-ok .match_answers__field {
  background: #dcf1dd;
  color: #5BC36C;
}
.task_solution--type-match--verdict-none .match_answers__field,
.task_solution--type-match--verdict-wrong .match_answers__field {
  background: #f8dcda;
  color: #f4425a;
}
.task_solution--type-match--verdict-partly .match_answers__field {
  background: #FFF5EB;
  color: #ff8e25;
}
.task_solution--type-match--verdict-annulled .match_answers__field {
  background: #ecf4ff;
}
.task_solution--type-match--verdict-unscored .match_answers__field {
  background: #ecf4ff;
}
.task_solution--type-match--verdict-notReady .match_answers__field {
  background: #ecf4ff;
}
.single_task {
  padding-top: 25px;
  padding-bottom: 112px;
}
.single_task .match_answers {
  padding: 10px 0;
}
.single_task--grouped {
  border-radius: 0;
  border-bottom: 1px solid #DDE4ED;
  min-height: 0;
  padding-bottom: 200px;
  position: relative;
  margin: 0 -50px;
  padding-left: 50px;
  padding-right: 50px;
}
.single_task--grouped:last-child {
  border-bottom: none;
}
@media (max-width: 768px) {
  .single_task--grouped {
    margin: 0 -15px;
    padding: 40px 15px 15px;
  }
}
.single_task--has-result-data:last-child {
  padding-bottom: 150px;
}
.single_task--show_right_answer .task__input--result_right {
  z-index: 100;
}
.single_task--show_right_answer .match_answers {
  z-index: 100;
  margin: 10px -25px;
  padding: 10px 25px;
  background: #fcfcf9;
  border-radius: 8px;
}
.single_task--show_right_answer .select_on_image_answer {
  z-index: 100;
  position: relative;
  background: #fcfcf9;
  border-radius: 8px;
}
.single_task--show_right_answer .task__result-switch {
  z-index: 100;
}
.single_task--show_right_answer .task__composite_input {
  background: #fcfcf9;
  border-radius: 8px;
  z-index: 100;
  position: relative;
  margin: 0 -25px;
  padding: 0 25px 8px;
}
.single_task--show_right_answer .task__composite_input--type-detailed_nofile,
.single_task--show_right_answer .task__composite_input--type-detailed {
  background: none;
  z-index: unset;
}
.single_task--show_right_answer .task__composite_answers {
  position: relative;
}
.single_task--show_right_answer .task__overlay_input {
  position: absolute;
  z-index: 100;
  background: #fff;
  left: 0;
  right: 0;
  border-radius: 8px;
  top: 50%;
  margin-top: -41px;
}
.single_task--hide_text_content {
  color: transparent;
  position: relative;
}
.single_task--noselect {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
@media (max-width: 768px) {
  .single_task {
    padding-bottom: 0;
  }
  .single_task--has-result-data:last-child {
    padding-bottom: 15px;
  }
  .single_task--show_right_answer .match_answers {
    margin: 10px -10px;
    padding: 10px 10px;
  }
}
.task {
  background: #FCFCF9;
  border-radius: 8px;
  width: 100%;
  max-width: 815px;
  min-height: 645px;
  position: relative;
  box-sizing: border-box;
  padding: 40px 50px 40px 50px;
  color: #232943;
  margin: 50px auto 0;
}
.task .type_select:nth-last-child(2) {
  padding-bottom: 150px;
}
.task--grouped {
  padding-bottom: 0;
}
.task--save_btn_only {
  height: 130px;
}
.task--is-results {
  padding-bottom: 10px;
}
.task--is-results .task__footer {
  color: #333333;
}
.task:last-child {
  margin-bottom: 300px;
}
.task:first-child {
  margin-top: 100px;
}
.task__title {
  font-size: 24px;
  font-weight: bold;
}
.task__title--section {
  text-align: center;
  font-size: 48px;
  line-height: 56px;
  margin-top: 49px;
  margin-bottom: 30px;
}
.task--section {
  padding-bottom: 36px;
}
.task__section_content {
  display: flex;
  justify-content: center;
  min-height: 500px;
  flex-direction: column;
}
.task__section_description {
  font-size: 18px;
  line-height: 30px;
  color: #232943;
  max-width: 560px;
  font-weight: normal;
  margin: 0 auto 50px;
  width: 100%;
}
.task__section_description--left_aligned {
  text-align: left;
}
.task__section_description .iframe-youtube {
  width: 100%;
  height: auto;
  aspect-ratio: 1.77777778;
  overflow: hidden;
  border-radius: 10px;
}
.task--result {
  text-align: center;
  min-height: 520px;
  padding-bottom: 60px;
}
.task--result .task__title {
  font-size: 24px;
  margin-top: 40px;
}
.task--result .task__title--result {
  font-size: 32px;
}
.task--result .status_row {
  padding: 8px 60px 28px;
}
.task__text {
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 0.02em;
  font-weight: 500;
  position: relative;
}
.task__text img {
  max-width: 100%;
  mix-blend-mode: darken;
}
.task__text .iframe-youtube {
  width: 100%;
  min-height: 400px;
  overflow: hidden;
  border-radius: 10px;
}
@media (max-width: 768px) {
  .task__text .iframe-youtube {
    min-height: calc(56.25vw - 20px);
  }
}
.task__text--inline {
  font-size: 16px;
  line-height: 40px;
}
.task__text--printsrn {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #FCFCF9;
  color: initial;
  text-align: center;
}
.task__composite_input {
  padding-bottom: 8px;
}
.task__composite_input > .select-dropdown-answer {
  margin-bottom: 12px;
}
.task__input {
  min-height: 62px;
  font-size: 18px;
  letter-spacing: 0.02em;
  padding: 16px 25px;
  box-sizing: border-box;
  position: relative;
  margin: 10px -25px;
}
.task__input p {
  margin: 0;
}
.task__input input[type=checkbox] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  top: 50%;
  margin-top: -12px;
}
.task__input input[type=checkbox] ~ label {
  margin: 0;
}
.task__input input[type=checkbox] ~ label img {
  vertical-align: middle;
  margin: 0 5px;
}
.task__input input[type=checkbox] ~ label:before {
  display: inline-block;
  content: " ";
  vertical-align: text-bottom;
  border: 2px solid #A7B3C2;
  box-sizing: border-box;
  border-radius: 3px;
  width: 24px;
  height: 24px;
  margin-right: 24px;
  position: absolute;
  left: 25px;
  top: 50%;
  transform: translateY(-50%);
}
.task__input input[type=checkbox] ~ label:hover:before {
  border: 2px solid #4B8CEE;
}
.task__input input[type=checkbox]:checked ~ label:before {
  background: #4B8CEE url("../img/ok.svg") 50% no-repeat;
}
.task__input input[type=radio] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.task__input input[type=radio] ~ label {
  margin: 0;
}
.task__input input[type=radio] ~ label:before {
  display: inline-block;
  content: " ";
  vertical-align: text-bottom;
  border: 2px solid #A7B3C2;
  box-sizing: border-box;
  width: 24px;
  height: 24px;
  margin-right: 24px;
  border-radius: 50%;
  position: absolute;
  left: 25px;
  top: 50%;
  transform: translateY(-50%);
}
.task__input input[type=radio] ~ label:hover:before {
  border: 2px solid #4B8CEE;
}
.task__input input[type=radio]:checked ~ label:before {
  background: #4B8CEE;
  border-color: #4B8CEE;
  box-shadow: inset 0 0 0 3px #fff;
}
.task__input input[type=number],
.task__input input[type=text] {
  width: 100%;
  height: 50px;
  font-size: 16px;
  line-height: 50px;
  padding: 0 20px;
  background: #ECF4FF;
  border: 1px solid #D5DDE6;
  box-sizing: border-box;
  border-radius: 8px;
  -moz-appearance: textfield;
}
.task__input input[type=number]:focus,
.task__input input[type=text]:focus {
  border: 2px solid #4B8CEE;
  outline: none;
  background: white;
}
.task__input input[type=number]::-webkit-inner-spin-button,
.task__input input[type=text]::-webkit-inner-spin-button,
.task__input input[type=number]::-webkit-outer-spin-button,
.task__input input[type=text]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.task__input input[type=number]:invalid,
.task__input input[type=text]:invalid {
  box-shadow: none;
}
.task__input input:focus + .multiply_answer__icon--question {
  visibility: visible;
}
.task__input label {
  cursor: pointer;
  padding-left: 50px;
  display: block;
  line-height: 30px;
}
.task__input--error input {
  color: #FF5E73;
}
.task__input .task__fraction_view {
  position: absolute;
  z-index: 1;
  width: calc(100% - 50px);
}
.task__input--result_unscored input[type=radio]:checked ~ label:before,
.task__input--result_annulled input[type=radio]:checked ~ label:before,
.task__input--result_unscored input[type=checkbox]:checked ~ label:before,
.task__input--result_annulled input[type=checkbox]:checked ~ label:before {
  background: #232943;
  box-shadow: none;
}
.task__input--result_unscored input[type=radio]:checked ~ label,
.task__input--result_annulled input[type=radio]:checked ~ label,
.task__input--result_unscored input[type=checkbox]:checked ~ label,
.task__input--result_annulled input[type=checkbox]:checked ~ label {
  color: #232943;
  -webkit-text-fill-color: #232943;
}
.task__input--full_width input {
  width: 100% !important;
  box-sizing: border-box;
  height: 56px !important;
}
.task__input .multiply_answer__icon--question {
  right: 22px;
  top: 29px;
}
.task__input .multiply_answer__baloon--right {
  right: 19px !important;
}
.task__summary {
  line-height: 30px;
  letter-spacing: 0.02em;
  font-size: 18px;
}
.task__remain {
  line-height: 21px;
  font-size: 16px;
  text-align: center;
  color: #69798C;
  margin-top: 10px;
}
.task__footer {
  color: #69798C;
  font-size: 14px;
  box-sizing: border-box;
  margin-top: 44px;
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: baseline;
}
@media (max-width: 768px) {
  .task__footer {
    flex-direction: column;
    align-items: stretch;
  }
}
.task__footer-label {
  font-size: 14px;
  min-height: 56px;
  justify-content: center;
  align-items: flex-start;
}
@media (max-width: 768px) {
  .task__footer-label {
    align-items: center;
  }
}
.task__footer:has(.programming-answer__verdicts-last-score-pe) {
  align-items: initial;
}
.task__save_button {
  color: #69798C;
  line-height: 23px;
  font-size: 16px;
  height: 112px;
  box-sizing: border-box;
  position: absolute;
  bottom: 0;
  padding: 44px 50px;
  left: 0;
  right: 0;
}
.task__result-switch {
  float: right;
  cursor: pointer;
  line-height: 25px;
  font-size: 16px;
  letter-spacing: 0.02em;
  color: #69798C;
  position: relative;
  border: 1px solid #DDE4ED;
  border-radius: 20px;
  padding: 7px 70px;
  height: 40px;
  box-sizing: border-box;
  text-align: center;
  margin-right: -20px;
  background: #FCFCF9;
  user-select: none;
}
.task__result-switch:after {
  background: url("../img/eye.svg") 50% / contain no-repeat;
  width: 22px;
  height: 15px;
  display: inline-block;
  content: " ";
  vertical-align: middle;
  opacity: 0.3;
  position: absolute;
  right: 12px;
  top: 12px;
}
.task__result-switch:hover {
  background: #F5F5F5;
}
.task__result-switch:hover:after {
  opacity: 1;
}
.task__result-switch-right_answers {
  background: #EBEBEB;
  color: #69798C;
}
.task__result-switch-right_answers:after {
  opacity: 1;
}
.task__result-switch-right_answers:hover {
  background: #F5F5F5;
}
.task__result-switch-right_answers:hover:after {
  opacity: 0.3;
}
.task__result-switch-programming {
  font-weight: normal;
  font-size: inherit;
  line-height: 16px;
  color: #666666;
  position: relative;
  padding: 8px 12px;
  height: 32px;
  background: rgba(0, 0, 0, 0.04);
}
.task__result-switch-programming:after {
  opacity: 0;
}
.task__result-switch-programming:hover:after {
  opacity: 0;
}
.task__send_btn {
  display: inline-block;
  cursor: pointer;
  width: 285px;
  height: 40px;
  line-height: 25px;
  font-size: 18px;
  letter-spacing: 0.02em;
  color: #4B8CEE;
  border-radius: 20px;
  box-sizing: border-box;
  padding: 7px 0;
  margin: 60px 5px 0;
  border: 1px solid #4B8CEE;
}
.task__send_btn:hover {
  background: #60A9FF;
  color: #fff;
}
.task__send_btn.ui-button.ui-button-form-rounded {
  font-weight: 500;
  color: #2979FF;
  background-color: transparent;
}
.task__send_btn.ui-button.ui-button-form-rounded:not([disabled]):hover {
  background: #4A8EFF;
  color: #FFFFFF;
}
.task__blue_btn {
  text-align: center;
  cursor: pointer;
  width: 285px;
  height: 40px;
  line-height: 25px;
  font-size: 18px;
  letter-spacing: 0.02em;
  background: #4B8CEE;
  border-radius: 20px;
  box-sizing: border-box;
  padding: 7px 0;
  margin: 60px 5px 0;
  color: #fff;
}
.task__blue_btn:hover {
  background: #60A9FF;
  color: #fff;
}
.task__blue_btn--centered {
  margin: 0 auto;
}
.task__send_cancel_btn {
  display: inline-block;
  cursor: pointer;
  width: 285px;
  height: 40px;
  line-height: 25px;
  font-size: 18px;
  letter-spacing: 0.02em;
  color: #FFFFFF;
  background: #4B8CEE;
  border-radius: 20px;
  box-sizing: border-box;
  padding: 7px 0;
  margin: 60px 5px 0;
}
.task__send_cancel_btn:hover {
  background: #60A9FF;
}
.task__fraction_view {
  background: #E1DFE6;
  border-radius: 12px;
  line-height: 20px;
  width: 100%;
  padding: 12px 15px 15px 15px;
  box-sizing: border-box;
  margin: 7px 0 0 0;
  display: none;
}
.task__fraction_view--error {
  background: #FFEBED;
  color: #FF5E73;
}
input:focus ~ .task__fraction_view {
  display: block;
}
.task .task_result {
  background: #fcfcf9;
  border-radius: 8px;
  z-index: 100;
  position: relative;
  margin: 0 -25px;
  padding: 0 25px;
}
@media (max-width: 768px) {
  .task {
    padding: 40px 15px 15px;
    min-height: 0;
    max-width: 95%;
  }
  .task .type_checkbox,
  .task .type_radio {
    margin-left: 0;
    margin-right: 0;
  }
  .task__section_description {
    font-size: 16px;
    line-height: 28px;
  }
  .task__text {
    line-height: 28px;
  }
  .task__input {
    font-size: 16px;
    line-height: 28px;
    margin: 5px -25px;
  }
  .task__input--result {
    margin: 5px -5px;
  }
  .task__footer {
    text-align: center;
    padding: 25px 0;
    position: static;
    height: auto;
  }
  .task__save_button {
    text-align: center;
    padding: 25px 0;
    position: static;
    height: auto;
  }
  .task__result-switch {
    float: none;
    margin: 20px auto 0;
    font-size: 14px;
    padding: 7px 44px;
  }
  .task--save_btn_only {
    padding: 0;
  }
  .task__summary {
    line-height: 26px;
    letter-spacing: 0.02em;
    font-size: 16px;
    margin-bottom: 40px;
  }
  .task__title {
    font-size: 20px;
  }
  .task__title--section {
    margin-top: 28px;
    margin-bottom: 10px;
  }
  .task--result .task__title {
    font-size: 20px;
  }
  .task--result .status_row {
    padding: 8px 0px 20px;
    margin: 0 -10px;
  }
  .task__send_btn {
    display: block;
    margin: 15px auto;
  }
  .task__send_cancel_btn {
    display: block;
    margin: 15px auto;
  }
  .task .task_result {
    margin: 5px -5px;
  }
  .task .task__composite_input {
    margin: 5px -5px;
  }
}
.task__section {
  overflow: auto;
}
.input_select__control {
  border: 1px solid #D5DDE5;
  border-radius: 8px !important;
  overflow: hidden;
  height: 56px;
}
.input_select__value-container {
  padding: 2px 15px !important;
}
.input_select__value-container,
.input_select__indicators {
  background: #ECF4FF;
  height: inherit;
}
.input_select__placeholder {
  color: #A7B3C2 !important;
  line-height: 22px;
  font-size: 18px;
}
.input_select__indicator-separator {
  display: none;
}
.input_select__menu {
  border-radius: 8px !important;
  border: 1px solid #D5DDE6;
  z-index: 1000 !important;
}
.task_page--timeout .input_select__menu {
  z-index: 1 !important;
}
.input_select__option {
  line-height: 28px;
  font-size: 18px;
  color: #232943 !important;
  padding: 11px 12px !important;
}
.input_select__option--is-selected,
.input_select__option--is-focused {
  background: #F2F2F2 !important;
}
.prev-task,
.next-task {
  cursor: pointer;
  background: url("../img/arrow.svg") 50% no-repeat;
  width: 50px;
  height: 50px;
  top: 50%;
  position: fixed;
  margin-top: -25px;
  border-radius: 50%;
}
.prev-task:hover,
.next-task:hover {
  background-color: rgba(255, 255, 255, 0.2);
}
@media (max-width: 768px) {
  .prev-task,
  .next-task {
    background-size: 12px;
    background-color: #67809f;
    z-index: 101;
    bottom: 30px;
    top: auto;
    width: 40px;
    height: 40px;
  }
  .prev-task:hover,
  .next-task:hover {
    background-color: #67809f;
  }
  .prev-task.attached,
  .next-task.attached {
    position: absolute;
    margin-top: 25px;
  }
}
.prev-task {
  left: 25px;
  transform: scale(-1);
}
@media (max-width: 768px) {
  .prev-task {
    left: 20px;
  }
}
.next-task {
  right: 25px;
}
@media (max-width: 768px) {
  .next-task {
    right: 20px;
  }
}
.task_selector {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: #445A76;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
  font-size: 0;
  z-index: 100;
}
.task_selector--left_aligned {
  text-align: left;
}
.task_selector__task_selector_wrapper {
  height: 70px;
  transition: height 0.25s 0.1s ease-in-out;
  overflow: hidden;
}
.task_selector__tasks_wrapper {
  height: 50px;
  overflow: hidden;
  transition: height 0.25s 0.1s ease-in-out;
  box-sizing: border-box;
}
.task_selector__section {
  position: relative;
  padding: 10px 20px;
  overflow: hidden;
  transition: height 0.25s 0.1s ease-in-out;
  box-sizing: border-box;
}
.task_selector__time {
  color: #FFFFFF;
  font-size: 18px;
  text-align: left;
  text-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
  height: 22px;
  position: absolute;
  top: -30px;
  left: 15px;
}
.task_selector__score {
  color: #FFFFFF;
  font-size: 18px;
  text-align: right;
  width: 150px;
  height: 22px;
  position: absolute;
  top: -30px;
  right: 10px;
}
.task_selector__group {
  display: inline-block;
  margin: 0 5px;
  border-radius: 25px;
  background: rgba(255, 255, 255, 0.2);
}
.task_selector__group > div {
  margin: 1px 5px;
}
.task_selector__group > div:first-child {
  margin-left: 1px;
}
.task_selector__group > div:last-child {
  margin-right: 1px;
}
.task_selector__task {
  cursor: pointer;
  vertical-align: middle;
  display: inline-block;
  height: 40px;
  border: 2px solid rgba(255, 255, 255, 0.2);
  background: #445A76;
  text-align: center;
  color: #fff;
  font-size: 16px;
  margin: 4px;
  box-sizing: border-box;
  line-height: 16px;
  font-weight: 500;
  padding: 10px;
  border-radius: 25px;
  min-width: 40px;
}
.task_selector__task:hover {
  border-color: #EDEDED;
}
.task_selector__task--not_saved {
  background: #FD9601;
}
.task_selector__task--not_saved:hover {
  background: #F28001;
  border-color: #fff;
}
.task_selector__task--section {
  height: auto;
  min-height: 40px;
}
.task_selector__task--saved {
  background: #2979FF;
  color: #fff;
  border-color: transparent;
}
.task_selector__task--saved:hover {
  background: #005FFF;
  border-color: #fff;
}
.task_selector__task--active-in-group,
.task_selector__task--active {
  border: 2px solid #ffffff;
}
.task_selector__task--result {
  background: url("../img/plane.svg") 60% 50% no-repeat;
}
.task_selector__task--result:hover {
  background-color: rgba(255, 255, 255, 0.1);
  border-color: #fff;
}
.task_selector__task--pending.task_selector__task--saved:hover,
.task_selector__task--pending.task_selector__task--saved,
.task_selector__task--pending {
  border: 2px solid transparent !important;
}
.task_selector__task--result_right {
  background: #5BC36C !important;
  color: #fff;
}
.task_selector__task--result_right:hover {
  background: #6BCF7B !important;
  border-color: #fff;
}
.task_selector__task--result_not_right {
  background: #f4425a !important;
  color: #fff;
}
.task_selector__task--result_not_right:hover {
  background: #FF6176 !important;
  border-color: #fff;
}
.task_selector__task--result_partial_right {
  background: #FF8E25 !important;
  color: #fff;
}
.task_selector__task--result_partial_right:hover {
  background: #FF9D43 !important;
  border-color: #fff;
}
.task_selector__task--result_unscored,
.task_selector__task--result_annulled {
  background: #596f8c !important;
  color: rgba(255, 255, 255, 0.5);
}
.task_selector__task--result_unscored:hover,
.task_selector__task--result_annulled:hover {
  background: #6080A9 !important;
  color: #fff;
  border-color: #fff;
}
.task_selector__task--dotted {
  font-size: 0;
}
.task_selector__task--dotted:before {
  background: #EDEDED;
  border: 1px solid #EDEDED;
  box-sizing: border-box;
  display: inline-block;
  content: " ";
  width: 16px;
  height: 16px;
  border-radius: 50%;
}
.task_selector__task--dotted .task_selector__pending-progress {
  margin-left: 8px;
  margin-top: -16px;
}
.task_selector__task--questions {
  font-size: 0;
}
.task_selector__task--questions:before {
  font-size: 16px;
  display: inline-block;
  content: "?";
}
.task_selector__task--questions .task_selector__pending-progress {
  margin-left: 8px;
  margin-top: -14px;
}
.task_selector__pending-progress {
  position: absolute;
  margin-left: 8px;
  margin-top: 8px;
  transform: scale(0.74);
}
.task_selector__pending-progress .c-progress-size-big .c-progress__path {
  stroke-width: 2px;
}
@media (max-width: 768px) {
  .task_selector {
    position: relative;
  }
  .task_selector__task_selector_wrapper {
    height: auto;
  }
  .task_selector__tasks_wrapper {
    height: auto;
  }
  .task_selector__section {
    height: auto;
  }
}
.multiply_right_answers {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  z-index: 100;
  pointer-events: none;
  visibility: hidden;
}
.multiply_right_answers--visible {
  visibility: visible;
}
.multiply_answer {
  width: 222px;
  padding: 0;
  box-sizing: border-box;
  display: inline-block;
  vertical-align: middle;
  margin: 20px 0 20px 22px;
  position: relative;
}
.multiply_answer .task__fraction_view {
  position: absolute;
  z-index: 1;
}
.multiply_answer input {
  width: 100%;
}
.multiply_answer--fraction-allowed + .multiply_answer__icon--remove {
  visibility: visible;
}
.multiply_answer--error input {
  color: #FF5E73;
}
.multiply_answer__input {
  width: 150px;
  background: #ECF4FF;
  border: 1px solid #D5DDE6;
  box-sizing: border-box;
  border-radius: 8px;
  height: 56px;
  line-height: 22px;
  font-size: 18px;
  padding: 0 16px;
  outline: none;
  -moz-appearance: textfield;
}
.multiply_answer__input:focus {
  border: 2px solid #4B8CEE;
}
.multiply_answer__input--result_right {
  background: #E1F5E4;
  color: #5BC36C;
  -webkit-text-fill-color: #5BC36C;
}
.multiply_answer__input--result_wrong {
  background: #FFF1F2;
  color: #F4425A;
}
.multiply_answer__input--result_partial_wrong {
  background: #FFF5EB;
  color: #ff8e25;
}
.multiply_answer__input::-webkit-inner-spin-button,
.multiply_answer__input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.multiply_answer__input:focus {
  background: white;
}
.multiply_answer__input:focus ~ .multiply_answer__icon--remove {
  visibility: hidden !important;
}
.multiply_answer__input:focus + .multiply_answer__icon--question {
  visibility: visible;
}
.multiply_answer:hover .multiply_answer__icon--remove {
  visibility: visible;
}
.multiply_answer__icon {
  display: inline-block;
  margin: 0 14px;
  width: 20px;
  height: 23px;
  vertical-align: middle;
  cursor: pointer;
  position: relative;
}
.multiply_answer__icon:hover + .multiply_answer__baloon {
  visibility: visible;
  opacity: 1;
}
.multiply_answer__icon:hover .multiply_answer__baloon {
  visibility: visible;
  opacity: 1;
}
.multiply_answer__icon svg {
  width: inherit;
  height: inherit;
}
.multiply_answer__icon--question {
  position: absolute;
  right: 0;
  top: 17px;
  visibility: hidden;
}
.multiply_answer__icon--question use,
.multiply_answer__icon--question path {
  fill: #CAD4E0;
}
.multiply_answer__icon--add {
  background: transparent;
  width: 100%;
  height: 56px;
  border: 1px solid #E1DFE6;
  box-sizing: border-box;
  border-radius: 12px;
  margin: 0;
  position: relative;
  outline: none !important;
}
.multiply_answer__icon--add .multiply_answer__baloon {
  width: 110px;
  right: 0;
  left: initial;
}
.multiply_answer__icon--add use,
.multiply_answer__icon--add path {
  fill: #CAD4E0;
}
.multiply_answer__icon--add svg {
  width: 26px;
}
.multiply_answer__icon--add[disabled] {
  cursor: auto;
}
.multiply_answer__icon--add[disabled] use,
.multiply_answer__icon--add[disabled] path {
  fill: rgba(202, 212, 224, 0.59);
}
.multiply_answer__icon--remove {
  visibility: hidden;
  position: absolute;
  right: 0;
  top: 17px;
}
.multiply_answer__icon--remove .multiply_answer__baloon {
  width: 110px;
  right: -15px;
  top: -77px;
  bottom: auto;
}
.multiply_answer__icon--remove:hover use,
.multiply_answer__icon--remove:hover path {
  fill: #4B8CEE;
}
.multiply_answer__icon--remove use,
.multiply_answer__icon--remove path {
  fill: #CAD4E0;
}
.multiply_answer__icon--remove svg {
  transform: rotate(45deg);
}
.multiply_answer__baloon {
  background: #FFFFFF;
  border: 1px solid #DDE4ED;
  box-shadow: 0 4px 8px rgba(202, 212, 224, 0.5);
  border-radius: 8px;
  position: absolute;
  padding: 10px;
  bottom: calc(100% - 10px);
  width: 226px;
  left: -15px;
  pointer-events: none;
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 18px;
  color: #69798C;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s;
}
.multiply_answer__baloon:after {
  display: block;
  content: " ";
  background: url(../img/icons.svg?#balloon_corner);
  width: 28px;
  height: 13px;
  position: absolute;
  bottom: -12px;
  box-shadow: inset 0 1px 0 0 #ffffff;
}
.multiply_answer__baloon--right {
  background: white;
  right: 0;
  left: auto;
  max-width: 200px;
}
.multiply_answer__baloon--right:after {
  right: 6px;
  left: auto;
}
@media (max-width: 768px) {
  .multiply_answer__baloon {
    font-size: 12px;
    width: 110px;
  }
}
.text-center {
  text-align: center;
  display: block;
}
.text-right {
  text-align: right;
  display: block;
}
.text-left {
  text-align: left;
  display: block;
}
.task-video-content__inner-video {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  margin-top: 10px;
  margin-bottom: 35px;
  overflow: hidden;
  border-radius: 10px;
  cursor: pointer;
}
.task-video-content__inner-video [data-video],
.task-video-content__inner-video video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.smt-login-page,
.login_page {
  text-align: center;
  padding-top: 60px;
}
.smt-login-page__gerb,
.login_page__gerb {
  background: url('../img/logo.svg') 50% / contain no-repeat;
  width: 32px;
  height: 38px;
  margin: 0 auto;
}
.smt-login-page__dep,
.login_page__dep {
  line-height: 24px;
  font-size: 14px;
  letter-spacing: 0.02em;
  color: #232943;
  margin-top: 20px;
}
.smt-login-page__title,
.login_page__title {
  line-height: 48px;
  font-size: 48px;
  letter-spacing: -0.01em;
  color: #1b3c68;
  font-weight: 500;
  margin: 70px auto 0;
  max-width: 700px;
}
.smt-login-page__title-small,
.login_page__title-small,
.smt-login-page__title--small,
.login_page__title--small {
  line-height: 44px;
  font-size: 30px;
  text-align: center;
  letter-spacing: -0.01em;
}
.smt-login-page__school,
.login_page__school {
  line-height: 44px;
  font-size: 14px;
  text-align: center;
  letter-spacing: -0.01em;
  padding: 5px 0;
}
.smt-login-page__school--hidden,
.login_page__school--hidden {
  display: none;
}
.smt-login-page .circular-progress,
.login_page .circular-progress {
  margin-top: 130px;
}
@media (max-width: 768px) {
  .smt-login-page,
  .login_page {
    padding-top: 30px;
  }
  .smt-login-page__title,
  .login_page__title {
    line-height: 30px;
    font-size: 26px;
    padding: 0 15px;
    margin: 30px auto 0;
  }
  .smt-login-page__title--small,
  .login_page__title--small {
    line-height: 24px;
    font-size: 20px;
  }
  .smt-login-page__school,
  .login_page__school {
    font-size: 12px;
  }
  .smt-login-page__dep,
  .login_page__dep {
    margin-top: 10px;
    font-size: 12px;
    line-height: 16px;
  }
  .smt-login-page footer,
  .login_page footer {
    margin-top: 90px;
    font-size: 12px;
  }
}
.user_code {
  border: 1px solid #d5dde6;
  border-radius: 8px;
  text-align: center;
  padding: 30px 32px;
  width: 460px;
  margin: 70px auto 0;
  box-sizing: border-box;
  max-width: 95%;
}
.user_code__title {
  line-height: 26px;
  font-size: 18px;
  letter-spacing: 0.02em;
  color: #232943;
  margin-bottom: 50px;
}
.user_code__btn {
  box-sizing: border-box;
  width: 100%;
  color: #fff;
  background: #4B8CEE;
  height: 42px;
  font-size: 18px;
  border-radius: 21px;
  line-height: 42px;
  margin-top: 25px;
  cursor: default;
}
.user_code__btn:hover {
  background: #60A9FF;
}
.user_code__btn--disabled {
  color: rgba(255, 255, 255, 0.5);
}
.user_code__btn--disabled:hover {
  background: #4B8CEE;
}
.user_code__btn--enabled {
  color: #fff;
  cursor: pointer;
}
@media (max-width: 768px) {
  .user_code {
    margin: 40px auto 0;
  }
  .user_code__title {
    font-size: 18px;
    line-height: 24px;
  }
}
@media (max-width: 460px) {
  .user_code {
    padding: 30px 15px;
  }
  .user_code__title {
    margin-bottom: 35px;
  }
  .user_code__btn {
    margin-top: 10px;
    height: 62px;
    line-height: 62px;
    border-radius: 8px;
  }
}
.login_form_input {
  position: relative;
  height: 100px;
}
.login_form_input input[type='checkbox'] {
  height: 20px;
  width: 20px;
  margin-right: 8px;
}
.login_form_input input[type='text'] {
  background: #eef1f5;
  border-radius: 4px 4px 0 0;
  border: none;
  padding: 27px 15px 11px;
  line-height: 24px;
  font-size: 16px;
  letter-spacing: 0.02em;
  width: 100%;
  box-sizing: border-box;
}
.login_form_input input[type='text']::placeholder {
  color: #69798c;
}
.login_form_input input[type='text'] + label {
  position: absolute;
  left: 15px;
  top: 7px;
  color: #9194a1;
  line-height: 18px;
  font-size: 12px;
  letter-spacing: 0.02em;
  display: block;
}
.login_form_input input[type='text']:placeholder-shown {
  padding: 19px 15px;
}
.login_form_input input[type='text']:placeholder-shown + label {
  display: none;
}
.login_form_input input[type='text'].error ~ .login_form_input__error_message {
  display: block;
}
.login_form_input input[type='text'].error + label {
  color: #F4425A;
}
.login_form_input .user_info_form_select-container--has_selected .user_info_form_select__control {
  padding: 27px 15px 11px !important;
}
.login_form_input .user_info_form_select-container--has_selected + label {
  display: block !important;
}
.login_form_input .user_info_form_select-container + label {
  display: none;
  position: absolute;
  left: 15px;
  top: 7px;
  color: #9194a1;
  line-height: 18px;
  font-size: 12px;
  letter-spacing: 0.02em;
}
.login_form_input .user_info_form_select__control {
  background: #eef1f5 !important;
  border-radius: 4px 4px 0 0 !important;
  border: none !important;
  padding: 19px 15px !important;
  font-size: 16px !important;
  letter-spacing: 0.02em !important;
  width: 100% !important;
  box-sizing: border-box !important;
}
.login_form_input .user_info_form_select__value-container {
  padding: 0 !important;
}
.login_form_input .user_info_form_select__indicator-separator {
  display: none;
}
.login_form_input__error_message {
  color: #F4425A;
  line-height: 18px;
  font-size: 12px;
  letter-spacing: 0.02em;
  text-align: left;
  padding-left: 15px;
  padding-top: 10px;
  display: none;
}
@media (max-width: 768px) {
  .login_form_input {
    height: auto;
  }
  .login_form_input input[type='text'] {
    font-size: 18px;
    border-radius: 8px;
  }
  .login_form_input__error_message {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}
.user_info_form {
  border: 1px solid #d5dde6;
  text-align: left;
  border-radius: 8px;
  padding: 30px 32px;
  width: 460px;
  margin: 70px auto 0;
  box-sizing: border-box;
  max-width: 95%;
}
.user_info_form__title {
  line-height: 26px;
  font-size: 18px;
  letter-spacing: 0.02em;
  color: #232943;
  margin-bottom: 20px;
}
.user_info_form__title2 {
  line-height: 26px;
  font-size: 18px;
  letter-spacing: 0.02em;
  color: #232943;
  margin-bottom: 20px;
  margin-top: 30px;
}
.user_info_form .login_form_input {
  height: auto;
  margin: 20px 0;
}
.user_info_form__btn {
  box-sizing: border-box;
  width: 100%;
  color: #fff;
  background: #4B8CEE;
  height: 42px;
  font-size: 18px;
  border-radius: 21px;
  line-height: 42px;
  margin-top: 50px;
  cursor: default;
  text-align: center;
}
.user_info_form__btn:hover {
  background: #60A9FF;
}
.user_info_form__btn--disabled {
  color: rgba(255, 255, 255, 0.5);
}
.user_info_form__btn--disabled:hover {
  background: #4B8CEE;
}
.user_info_form__btn--enabled {
  color: #fff;
  cursor: pointer;
}
.user_info_form input[type='radio'] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.user_info_form input[type='radio'] ~ label {
  display: block;
  padding: 12px 0 12px 36px;
}
.user_info_form input[type='radio'] ~ label:before {
  display: inline-block;
  content: ' ';
  vertical-align: text-bottom;
  border: 2px solid #a7b3c2;
  box-sizing: border-box;
  width: 24px;
  height: 24px;
  margin-right: 24px;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -12px;
}
.user_info_form input[type='radio'] ~ label:hover:before {
  border: 2px solid #4b8cee;
}
.user_info_form input[type='radio']:checked ~ label:before {
  background: #4b8cee;
  border-color: #4b8cee;
  box-shadow: inset 0 0 0 3px #fff;
}
@media (max-width: 768px) {
  .user_info_form {
    margin: 45px auto 0;
  }
  .user_info_form__btn {
    margin-top: 10px;
    height: 62px;
    line-height: 62px;
    border-radius: 8px;
  }
}
@media (max-width: 460px) {
  .user_info_form {
    padding: 30px 15px;
  }
  .user_info_form__btn {
    margin-top: 0;
  }
}
.certificate_page {
  text-align: center;
  color: #232943;
}
.certificate_page__title {
  font-size: 36px;
  line-height: 48px;
  text-align: center;
  font-weight: 500;
  padding-top: 90px;
  padding-bottom: 20px;
  max-width: 700px;
  margin: 0 auto;
}
@media (max-width: 768px) {
  .certificate_page__title {
    width: calc(100% - 16px);
  }
}
.certificate_page__text {
  font-size: 18px;
  line-height: 26px;
  max-width: 700px;
  margin: 0 auto;
}
@media (max-width: 768px) {
  .certificate_page__text {
    width: calc(100% - 16px);
  }
}
.certificate_page__img {
  box-shadow: 0 8px 32px rgba(37, 129, 194, 0.25);
  border-radius: 16px;
  margin: 55px auto 70px;
  max-height: 565px;
  display: block;
}
@media (max-width: 768px) {
  .certificate_page__img {
    max-width: calc(100% - 32px);
  }
}
.certificate_page__link {
  font-size: 18px;
  line-height: 30px;
  width: 500px;
  margin: 0 auto;
}
.certificate_page__link > input {
  border: 1px solid #E0E0E0;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 10px 15px;
  display: inline-block;
  width: inherit;
  margin-top: 15px;
  font-size: inherit;
  line-height: inherit;
  text-align: center;
}
@media (max-width: 768px) {
  .certificate_page__link {
    max-width: 500px;
    width: calc(100% - 16px);
  }
}
.certificate_page__links {
  margin-top: 35px;
  font-size: 18px;
  line-height: 30px;
}
.certificate_page__links span {
  margin: 0 15px;
}
.certificate_page__links a {
  color: #4B8CEE;
  text-decoration: none;
}
@media (max-width: 768px) {
  .certificate_page__links span {
    display: inline-block;
  }
}
.certificate_page__qr {
  display: block;
  margin: 40px auto;
}
.certificate_page__social {
  font-size: 0;
  text-align: center;
  margin: 50px 0;
}
.certificate_page__social > .react-share__ShareButton {
  display: inline-block;
  margin: 0 14px;
  cursor: pointer;
}
.certificate_page__social > .react-share__ShareButton:hover {
  opacity: 0.8;
}
@media (max-width: 768px) {
  .certificate_page__social > .react-share__ShareButton {
    margin: 0 10px;
  }
}
.certificate_page__to_contenst_btn {
  color: #4B8CEE;
  font-size: 18px;
  line-height: 26px;
  background: #FFFFFF;
  border: 2px solid #4B8CEE;
  box-sizing: border-box;
  box-shadow: 0 2px 6px rgba(64, 77, 92, 0.35);
  border-radius: 20px;
  width: 260px;
  height: 40px;
  display: block;
  margin: 50px auto;
  cursor: pointer;
  outline: none;
}
.certificate_page__to_contenst_btn:hover {
  background: #60A9FF;
  color: #fff;
}
.certificate_page a {
  text-decoration: none;
  color: #4B8CEE;
}
.certificate_page a:hover {
  color: #60A9FF;
}
.certificate_page_header {
  text-align: center;
  padding-top: 60px;
}
.certificate_page_header__logo {
  background: url("../img/logo.svg") 50% / contain no-repeat;
  width: 32px;
  height: 38px;
  margin: 0 auto;
}
.certificate_page_header__title {
  font-size: 14px;
  line-height: 24px;
  color: #232943;
  margin: 20px auto 0;
  max-width: 700px;
}
@media (max-width: 768px) {
  .certificate_page_header__title {
    width: calc(100% - 16px);
  }
}
.certificate_page_header__sub_title {
  font-size: 18px;
  line-height: 26px;
  color: #232943;
  margin: 5px auto 0;
  max-width: 700px;
  font-weight: 500;
}
.circular-progress {
  animation: rotate 2s linear infinite;
  height: 100px;
  position: relative;
  width: 100px;
}
.circular-progress--center-of-window {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -50px;
  margin-top: -50px;
}
.circular-progress--margin-center {
  display: block;
  margin: 0 auto;
}
.circular-progress .path {
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  animation: dash 1.5s ease-in-out infinite;
  stroke-linecap: round;
  stroke: #33a9ff;
}
@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124;
  }
}
html,
body,
#index {
  height: 100%;
}
body {
  font-family: "PT Root UI", Arial, sans-serif;
  margin: 0;
  overflow: auto;
  scrollbar-color: rgba(169, 169, 169, 0.31) rgba(218, 218, 218, 0.25);
  scrollbar-width: thin;
}
body::-webkit-scrollbar {
  height: 5px;
  width: 5px;
  border-radius: 10px;
}
body::-webkit-scrollbar-track {
  background: rgba(218, 218, 218, 0.25);
}
body::-webkit-scrollbar-thumb {
  background: rgba(169, 169, 169, 0.31);
  border-radius: 5px;
}
body::-webkit-scrollbar-thumb:hover {
  background: rgba(218, 218, 218, 0.45);
}
body.no_scrollable {
  overflow: hidden;
}
body.locked {
  scroll-behavior: smooth;
}
.header {
  text-align: center;
  margin-bottom: 120px;
  position: relative;
  background: #F7F5F0 50% 100% / cover no-repeat;
}
.header__wrapper {
  position: relative;
  z-index: 2;
  padding-top: 130px;
}
.header__company {
  font-size: 14px;
  line-height: 24px;
  color: #232943;
}
.header__title {
  font-size: 36px;
  line-height: 48px;
  color: #1B3C68;
  margin: 83px auto 0;
  max-width: 660px;
  font-weight: 500;
}
.header__button {
  background: #F4425A;
  box-shadow: 0 2px 10px rgba(167, 179, 194, 0.15);
  border-radius: 55px;
  width: 750px;
  color: #FFF;
  text-align: center;
  margin: 0 auto;
  line-height: 30px;
  font-size: 18px;
  position: relative;
  top: 50px;
  box-sizing: border-box;
  padding: 25px 20px;
  margin-top: -45px;
  max-width: 95%;
}
.header__button:hover {
  background: #FF6176;
}
.header__button_to_tasks {
  display: none;
  background: #4B8CEE;
  box-shadow: 0 2px 10px rgba(64, 77, 92, 0.25);
  border-radius: 20px;
  color: #fff;
  line-height: 40px;
  font-size: 18px;
  width: 250px;
  margin: 15px 15px 0;
  cursor: pointer;
}
.header__button_to_tasks:hover {
  background: #60A9FF;
}
.header__button_to_certificate {
  display: none;
  background: #FFFFFF;
  border: 2px solid #4B8CEE;
  box-shadow: 0 2px 10px rgba(64, 77, 92, 0.25);
  border-radius: 20px;
  color: #4B8CEE;
  line-height: 40px;
  font-size: 18px;
  width: 250px;
  margin: 15px 15px 0;
  cursor: pointer;
}
.header__button_to_certificate:hover {
  background: #60A9FF;
  color: #fff;
}
.header__button--not-yet-started {
  background: #F4425A;
  padding: 40px 20px;
}
.header__button--not-yet-started:hover {
  background: #F4425A;
}
.header__button--finished {
  background: #63C5E4;
  color: #232943;
}
.header__button--finished:hover {
  background: #63C5E4;
}
.header__button--finished_with_button {
  background: #63C5E4;
  color: #232943;
}
.header__button--finished_with_button:hover {
  background: #63C5E4;
}
.header__button--finished_with_button .header__button_to_tasks {
  display: inline-block;
}
.header__button--finished_with_certificate {
  background: #fff;
  color: #232943;
  box-shadow: 0 2px 16px rgba(68, 90, 118, 0.25);
}
.header__button--finished_with_certificate:hover {
  background: #fff;
}
.header__button--finished_with_certificate .header__button_to_tasks {
  display: inline-block;
}
.header__button--finished_with_certificate .header__button_to_certificate {
  display: inline-block;
}
.header__button--running {
  cursor: pointer;
  min-height: 0;
  height: 30px;
  padding: 5px 20px;
  width: 180px;
  border: solid #FFF 35px;
  box-sizing: content-box;
}
.header--custom-color .header__title {
  color: inherit;
}
.header--custom-color .header__company {
  color: inherit;
}
@media (max-width: 768px) {
  .header__title {
    font-size: 22px;
    line-height: 30px;
    padding: 15px;
    margin-top: 35px;
  }
  .header__wrapper {
    padding-top: 100px;
  }
  .header__button {
    font-size: 14px;
    line-height: 26px;
    border-radius: 24px;
  }
  .header__button--running {
    border-radius: 55px;
    line-height: 32px;
  }
  .header__button_to_tasks {
    line-height: 36px;
    font-size: 14px;
  }
}
.status_row {
  font-size: 0;
  padding: 43px;
}
.status_row_item {
  width: 150px;
  font-size: 14px;
  display: inline-block;
  color: #232943;
  margin: 0 10px;
  padding: 17px 0;
  border-radius: 8px;
}
.status_row_title {
  opacity: 0.5;
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 24px;
}
.status_row_text {
  font-size: 24px;
  line-height: 31px;
}
.status_row--custom-color .status_row_title {
  opacity: 1;
}
@media (max-width: 768px) {
  .status_row {
    padding: 15px 5px;
  }
  .status_row_item {
    width: 88px;
    margin: 0 4px;
    padding: 13px 0;
  }
  .status_row_title {
    margin-bottom: 0;
    font-size: 12px;
  }
  .status_row_text {
    font-size: 20px;
    line-height: 26px;
  }
}
.content {
  max-width: 660px;
  line-height: 26px;
  font-size: 18px;
  margin: 0 auto;
  letter-spacing: 0.02em;
  color: #232943;
  padding: 0 10px;
}
.content h2 {
  font-size: 24px;
  font-weight: 500;
  margin: 60px 0 30px 0;
}
.content p {
  margin: 25px 0;
}
.content p img {
  max-width: 100%;
}
.content .iframe-youtube {
  width: 100%;
  min-height: 400px;
  overflow: hidden;
  border-radius: 10px;
}
.content-description .iframe-youtube {
  min-height: initial;
}
@media (max-width: 768px) {
  .content {
    line-height: 22px;
    font-size: 14px;
  }
  .content h2 {
    text-align: center;
  }
  .content .iframe-youtube {
    min-height: calc(56.25vw - 20px);
  }
}
.prestart-popup--hidden {
  display: none;
}
.prestart-popup--show {
  display: block;
}
.prestart-popup__overlay {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  opacity: 0.8;
  background: #232943;
  z-index: 3;
}
.prestart-popup__body {
  width: 768px;
  height: 500px;
  background: #fcfcf9;
  border-radius: 24px;
  text-align: center;
  position: fixed;
  left: 50%;
  margin-left: -384px;
  top: 50%;
  margin-top: -250px;
  z-index: 4;
}
.prestart-popup__remain {
  margin-top: 50px;
  margin-bottom: 34px;
  line-height: 21px;
  font-size: 16px;
  text-align: center;
  color: #69798C;
  height: 36px;
}
.prestart-popup__condition {
  width: 500px;
  max-width: 100%;
  font-weight: 600;
  height: 92px;
  line-height: 36px;
  font-size: 24px;
  text-align: center;
  color: #232943;
  margin: 0 auto;
}
.prestart-popup__buttons {
  font-size: 0;
}
.prestart-popup__button {
  display: inline-block;
  width: 190px;
  height: 40px;
  padding: 7px;
  box-sizing: border-box;
  cursor: pointer;
  vertical-align: top;
  line-height: 25px;
  font-size: 18px;
  text-align: center;
  letter-spacing: 0.02em;
  border-radius: 20px;
  margin: 0 7px;
}
.prestart-popup__button--start {
  background: #F4425A;
  color: #FFF;
  padding: 8px;
}
.prestart-popup__button--start:hover {
  background: #FF6176;
}
.prestart-popup__button--cancel {
  color: #4B8CEE;
  background: #fcfcf9;
  border: 1px solid #4B8CEE;
}
.prestart-popup__button--cancel:hover {
  background: #60A9FF;
  color: #fff;
  border: none;
  padding: 8px;
}
@media (max-width: 768px) {
  .prestart-popup__body {
    width: 94%;
    margin-left: 0;
    left: 3%;
  }
  .prestart-popup__condition {
    line-height: 30px;
    font-size: 20px;
    height: auto;
    padding: 0 15px;
    box-sizing: border-box;
  }
  .prestart-popup__remain {
    margin-bottom: 15px;
  }
  .prestart-popup__button {
    width: 130px;
    font-size: 14px;
    line-height: 20px;
    height: 36px;
  }
}
.timeout-popup--hidden {
  display: none;
}
.timeout-popup--show {
  display: block;
}
.timeout-popup__overlay {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  opacity: 0.8;
  background: #232943;
  z-index: 1000;
}
.timeout-popup__body {
  width: 768px;
  height: 530px;
  background: #fcfcf9;
  border-radius: 24px;
  text-align: center;
  position: fixed;
  left: 50%;
  margin-left: -384px;
  top: 50%;
  margin-top: -265px;
  z-index: 1000;
}
.timeout-popup__title {
  font-size: 24px;
  text-align: center;
  font-weight: bold;
  color: #232943;
}
.timeout-popup__remain {
  margin-top: 50px;
  margin-bottom: 34px;
  line-height: 21px;
  font-size: 16px;
  text-align: center;
  color: #69798C;
  height: 36px;
}
.timeout-popup__condition {
  height: 92px;
  line-height: 30px;
  font-size: 18px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #404D5C;
  margin: 0 auto;
}
.timeout-popup__buttons {
  font-size: 0;
}
.timeout-popup .status_row {
  padding: 33px 50px 23px;
}
.timeout-popup__button {
  cursor: pointer;
  width: 310px;
  height: 40px;
  background: #4B8CEE;
  border-radius: 20px;
  line-height: 25px;
  font-size: 18px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #FFFFFF;
  box-sizing: border-box;
  padding: 7px 0;
  margin: 40px auto;
}
.timeout-popup__button:hover {
  background: #60A9FF;
}
@media (max-width: 768px) {
  .timeout-popup__body {
    width: 94%;
    margin-left: 0;
    left: 3%;
  }
  .timeout-popup__condition {
    line-height: 25px;
    font-size: 16px;
    height: auto;
    padding: 0 15px;
    box-sizing: border-box;
  }
  .timeout-popup__remain {
    margin-bottom: 15px;
  }
  .timeout-popup__button {
    width: 130px;
    font-size: 14px;
    line-height: 20px;
    height: 36px;
  }
  .timeout-popup .status_row {
    padding: 25px 15px 20px;
  }
}
.user_info {
  position: absolute;
  top: 0;
  right: 0;
  padding: 8px 10px;
  font-size: 14px;
  text-align: right;
  color: #232943;
}
.user_info__logout {
  background: #4B8CEE;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  border-radius: 16px;
  height: 32px;
  min-width: 78px;
  line-height: 32px;
  text-align: center;
  color: #fff;
  margin-left: 15px;
}
.user_info__logout:hover {
  background: #60A9FF;
}
.course_header {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  height: 60px;
}
.course_header__back_arrow {
  background: url("../img/auth_back_arrow.svg") 50% 50% no-repeat;
  position: absolute;
  width: 24px;
  height: 24px;
  left: 16px;
  top: 16px;
  cursor: pointer;
}
.course_header__title {
  font-size: 16px;
  line-height: 36px;
  color: #FFFFFF;
  position: absolute;
  height: 23px;
  left: 56px;
  top: 10px;
}
.parent_mode_text {
  background: rgba(0, 0, 0, 0.8);
  border-radius: 10px;
  position: fixed;
  bottom: 5px;
  left: 5px;
  right: 5px;
  color: #fff;
  text-align: center;
  line-height: 32px;
  font-size: 12px;
  z-index: 1000;
}
footer {
  text-align: center;
  min-height: 70px;
  font-size: 14px;
  color: #232943;
  opacity: 0.5;
  line-height: 24px;
  box-sizing: border-box;
  padding: 23px 0;
  margin-top: 150px;
}
