.prize_type_congrats {
  padding: 30px 280px 30px 45px;
  box-shadow: 0 2px 16px rgba(68, 90, 118, 0.25);
  border-radius: 24px;
  margin-bottom: 20px;
  background: #4F8991;
  color: #fff;
  min-height: 240px;
  position: relative;
}
.prize_type_congrats__title {
  font-weight: 500;
  font-size: 36px;
  line-height: 40px;
  margin-bottom: 22px;
}
.prize_type_congrats__description {
  font-size: 18px;
  line-height: 26px;
}
.prize_type_congrats__img {
  position: absolute;
  width: 200px;
  right: 40px;
  top: 52px;
  display: block;
  margin: auto;
}
@media (max-width: 680px) {
  .prize_type_congrats {
    padding: 24px;
  }
  .prize_type_congrats__img {
    position: static;
    width: 180px;
  }
  .prize_type_congrats__title {
    font-size: 24px;
    line-height: 32px;
  }
  .prize_type_congrats__description {
    font-size: 16px;
    line-height: 26px;
  }
}
