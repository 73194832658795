.smt-login-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100%;
  padding-top: 60px;
}
.smt-login-page__content {
  flex: 1 0 auto;
  width: 100%;
}
.smt-login-page__content .smt-login-page__dep,
.smt-login-page__content .smt-login-page__title {
  text-align: center;
}
.smt-login-page__footer {
  flex: 0 0 auto;
  text-align: center;
  min-height: 70px;
  font-size: 14px;
  color: #232943;
  opacity: 0.5;
  line-height: 24px;
  box-sizing: border-box;
  padding: 23px 0;
}
