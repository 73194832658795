.answer-comment {
  padding: 16px;
  background-color: #F2F2F2;
  border-radius: 16px;
  margin-top: 48px;
}
.answer-comment-expandable {
  background-color: transparent;
  padding-top: 0;
  margin-top: 16px;
  max-width: 434px;
  position: relative;
}
.answer-comment__title {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #666666;
}
.answer-comment__title-expandable {
  font-weight: 400;
  cursor: pointer;
  width: fit-content;
  align-items: center;
  margin-left: 8px;
}
.answer-comment__title-expanded {
  margin-bottom: 20px;
}
.answer-comment__title-disabled {
  opacity: 0.3;
}
.answer-comment__pending-icon {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 10px;
  width: 56px;
  height: 24px;
  background: #2979FF;
  border-radius: 9999px;
  margin-left: 27px;
}
.answer-comment__tooltip-icon {
  margin-left: 27px;
}
.answer-comment__tooltip {
  background-color: #FFFFFF;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  color: #1A1A1A;
  z-index: 100;
  max-width: 288px;
  left: 162px !important;
  top: -47px !important;
  line-height: 16px;
  cursor: default;
}
.answer-comment__text {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #1A1A1A;
  margin: 2px 0;
  cursor: pointer;
}
.answer-comment__text-disabled {
  opacity: 0.3;
}
.answer-comment__text-inner-video {
  position: relative;
  margin-top: 10px;
  margin-bottom: 35px;
  overflow: hidden;
  border-radius: 10px;
  cursor: pointer;
  aspect-ratio: 1.77777778;
  max-height: 226px;
}
.answer-comment__text-inner-video [data-video] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.answer-comment__preview {
  height: 170px;
  width: 150px;
  border-radius: 8px;
  cursor: pointer;
  position: relative;
  margin-right: 8px;
  margin-top: 8px;
  text-decoration: none;
}
.answer-comment__preview-wrapper {
  flex-wrap: wrap;
  margin-top: 16px;
}
.answer-comment__preview-wrapper-disabled {
  opacity: 0.3;
}
.answer-comment__preview:last-of-type {
  margin-right: 0;
}
.answer-comment__preview:hover::after {
  content: '';
  height: inherit;
  width: inherit;
  border-radius: inherit;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url("./download-icon.svg") no-repeat 10px 144px;
}
.answer-comment__preview-img {
  height: inherit;
  width: inherit;
  object-fit: cover;
  border-radius: inherit;
}
.answer-comment__preview-file {
  background-color: #ECF4FF;
  color: #2276F4;
  font-size: 24px;
  font-weight: 500;
  line-height: 20px;
  text-decoration: none;
  text-transform: lowercase;
  max-width: 100%;
  height: inherit;
  width: inherit;
  border-radius: inherit;
  padding: 16px;
  box-sizing: border-box;
}
.answer-comment-video-content__inner-video {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  margin-top: 10px;
  margin-bottom: 35px;
  overflow: hidden;
  border-radius: 10px;
  cursor: pointer;
}
.answer-comment-video-content__inner-video video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.single_task [data-video-wrapper] {
  width: 100%;
}
