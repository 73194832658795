/*rgba(0,0,0,.1);*/
.standings-page {
  padding: 48px;
  z-index: 1;
  font-size: 14px;
}
.standings-page__sort-icon-up {
  line-height: 8px;
  max-height: 8px;
  font-size: 8px;
  color: #888888;
}
.standings-page__sort-icon-down {
  line-height: 8px;
  max-height: 8px;
  font-size: 8px;
  color: #888888;
}
.standings-page__cell-label {
  min-height: 32px;
}
.standings-page__cell-sort {
  min-width: 24px;
  max-width: 24px;
  text-align: center;
}
.standings-page__cell-sort.ui-button-size-xs {
  padding: 0px;
}
.standings-page__cell-sort-dir-unset {
  opacity: 0.5;
}
.standings-page__cell-sort-dir-asc,
.standings-page__cell-sort-dir-desc,
.standings-page__cell-sort-dir-unset {
  cursor: pointer;
}
.standings-page__panel {
  margin-top: 44px;
  width: 100%;
  height: calc(100% - 54px);
  background-color: #ffffff;
  border-radius: 8px;
  padding: 32px;
  z-index: 1;
}
.ui-screen-mobile .standings-page__panel {
  padding: 0px;
  margin-top: 66px;
  margin-bottom: 8px;
  border-radius: 0px;
}
.standings-page__filter-input {
  border: 0;
  background: transparent;
  border-bottom: 1px solid lightgrey;
  padding: 4px;
  margin-left: 8px;
}
.ui-screen-mobile .standings-page__filter-input {
  max-width: 120px;
}
.theme-light .standings-page__filter-button.ui-button.ui-button-mode-text {
  color: #888888;
}
.theme-light .standings-page__filter-button.ui-button {
  width: 150px;
  justify-content: flex-start;
}
.standings-page__scroll-button {
  max-width: 0;
  min-width: 0;
  justify-content: center;
}
.standings-page__scroll-button .standings-page__cell-label {
  position: absolute;
  overflow: visible;
  z-index: 20;
  margin-left: -32px;
}
.standings-page__scroll-button .standings-page__cell-label .ui-button-size-s {
  font-size: 32px;
}
.theme-light .standings-page__scroll-button .standings-page__cell-label .ui-button.ui-button-mode-secondary {
  background-color: white;
  box-shadow: 0px 0px 2px 0px lightgrey;
}
.ui-screen-mobile .standings-page {
  padding: 0px;
}
.standings-page__table {
  overflow-x: auto;
  overflow-y: hidden;
}
.standings-page__updatebutton {
  padding: 0px;
}
.ui-screen-mobile .standings-page__updatebutton {
  padding-top: 8px;
}
.standings-page__noresult {
  color: grey;
  text-align: center;
  padding: 64px 0;
  position: sticky;
  left: 0;
}
.ui-screen-mobile .standings-page__noresult {
  padding: 32px;
}
.standings-page__absnoresultwarning {
  white-space: pre;
  color: grey;
  font-weight: 100;
  line-height: 24px;
  margin-left: 8px;
}
.ui-screen-mobile .standings-page__absnoresultwarning {
  line-height: 16px;
  margin-left: 0px;
}
@media (max-width: 450px) {
  .standings-page__absnoresultwarning {
    white-space: normal;
    width: 35vw;
  }
}
.standings-page__fixed {
  position: fixed;
}
.standings-page__header {
  margin: 8px;
  padding: 0px;
  font-weight: 500;
}
.ui-screen-mobile .standings-page__header {
  margin: 8px 14px;
}
.standings-page__result-no-results {
  left: 400px;
  background: #00A3FF;
}
.standings-page__result-row {
  min-height: 44px;
  max-height: 44px;
}
.standings-page__result-row .b-table__cell {
  min-height: 44px;
  max-height: 44px;
  background-color: #ffffff;
}
.standings-page__result-row-place-delim {
  border-top: 1px solid lightgrey;
  margin-top: 1px;
}
.standings-page__result-place {
  min-width: 60px;
  max-width: 60px;
  left: 0px;
  z-index: 10;
}
.standings-page__result-row .b-table__cell.standings-page__result-place {
  justify-content: center;
}
.standings-page__result-row-current .standings-page__result-place {
  font-size: 14px;
  font-weight: 700;
}
.ui-screen-mobile .standings-page__result-row-current .standings-page__result-place {
  font-size: 14px;
}
.standings-page__result-row-current .standings-page__result-name {
  font-size: 14px;
  font-weight: 700;
}
.standings-page__result-row-header {
  background-color: #f0f0f0;
  color: #888888;
  min-height: 66px;
  max-height: 66px;
}
.standings-page__result-row-header .b-table__cell {
  background-color: #f0f0f0;
  min-height: 66px;
  max-height: 66px;
  align-items: flex-start;
  padding-top: 4px;
  padding-bottom: 4px;
}
.standings-page__result-row-header-position > .standings-page__result-place,
.standings-page__result-row-header-position > .standings-page__result-name {
  font-weight: normal;
}
.standings-page__result-name {
  left: 60px;
  min-width: 300px;
  padding-top: 0;
  padding-bottom: 0;
  justify-content: start;
  z-index: 10;
  border-right: 1px solid lightgrey;
}
.standings-page__result-name-link {
  text-decoration: underline;
  cursor: pointer;
}
.ui-screen-mobile .standings-page__result-name {
  min-width: 180px;
  max-width: 180px;
}
.standings-page__result-row:not(.standings-page__result-row-header) .standings-page__result-name {
  padding-left: 12px;
}
.standings-page__result-total {
  min-width: 90px;
  max-width: 90px;
}
.standings-page__result-score {
  z-index: 1;
  padding-left: 8px;
  min-width: 60px;
  max-width: 60px;
}
.standings-page__result-row-header .b-table__cell.standings-page__result-score {
  align-items: flex-end;
}
.standings-page__result-row:not(.standings-page__result-row-header) .standings-page__result-total {
  font-weight: 500;
}
.standings-page__result-total {
  left: 270px;
  z-index: 2;
}
.ui-screen-mobile .standings-page__result-total {
  left: 150px;
}
.standings-page__result-total.standings-page__result-total-no-results {
  left: 368px;
  z-index: 2;
}
.ui-screen-mobile .standings-page__result-total.standings-page__result-total-no-results {
  left: 248px;
}
.standings-page__result-row-header .standings-page__result-total:after {
  left: 90px;
  margin-top: 8px;
  margin-left: 8px;
  position: absolute;
  content: attr(data-numtasklabel);
  white-space: pre;
}
.standings-page__result-row .b-table__cell.standings-page__result-col-scroll-left,
.standings-page__result-row .b-table__cell.standings-page__result-col-scroll-right {
  z-index: 3;
  width: 8px;
  max-width: 8px;
  min-width: 8px;
  margin: 0;
  padding: 0;
}
.standings-page__result-row .b-table__cell.standings-page__result-col-scroll-left {
  left: 360px;
}
.standings-page__result-row .b-table__cell.standings-page__result-col-scroll-left-hide {
  visibility: hidden;
}
.ui-screen-mobile .standings-page__result-row .b-table__cell.standings-page__result-col-scroll-left {
  left: 240px;
}
.standings-page__result-row .b-table__cell.standings-page__result-col-scroll-right {
  right: 0px;
}
.standings-page__result-row .b-table__cell.standings-page__result-col-scroll-right-hide {
  visibility: hidden;
}
.standings-page__table-scroll-both .b-table__cell.standings-page__result-col-scroll-left,
.standings-page__table-scroll-right .b-table__cell.standings-page__result-col-scroll-left {
  background: linear-gradient(to right, rgba(211, 211, 211, 0.5), rgba(255, 255, 255, 0.01) 80%);
}
.standings-page__table-scroll-both .b-table__cell.standings-page__result-col-scroll-right,
.standings-page__table-scroll-left .b-table__cell.standings-page__result-col-scroll-right {
  background: linear-gradient(to left, rgba(211, 211, 211, 0.5), rgba(255, 255, 255, 0.01) 80%);
}
