.result-states-mode-spoiler {
  padding: 1px 0;
  max-width: 480px;
  margin: 0 auto;
  text-align: left;
}
.result-states-mode-spoiler .ui-spoiler__summary {
  align-items: start;
}
.result-states-mode-spoiler .ui-spoiler__icon {
  margin-top: 2px;
}
.result-states-mode-spoiler .ui-chips-list {
  margin-top: 0;
}
.result-states-mode-spoiler .result-states__task {
  min-width: 40px;
}
.result-states-mode-modal {
  padding: 8px;
}
.result-states-mode-modal .result-states__section {
  margin-top: 12px;
}
.result-states-mode-modal .ui-chips-list {
  margin-top: 16px;
  margin-bottom: 16px;
}
.result-states-mode-modal .result-states__task {
  min-width: 32px;
}
.result-states-status-not_saved .ui-spoiler__summary,
.result-states-status-not_saved .result-states__title {
  color: #E76A01;
}
.result-states-status-not_saved .ui-spoiler__icon,
.result-states-status-not_saved .ui-icon {
  color: #FD9601;
}
.result-states-status-not_saved .result-states__task {
  background-color: #FD9601;
  color: #FFFFFF;
}
.result-states-status-not_saved .result-states__task:hover {
  background-color: #F28001;
}
.result-states-status-not_answered .result-states__task {
  border-width: 2px;
  border-style: solid;
  border-color: #EDEDED;
  color: #999999;
  background-color: transparent;
}
.result-states-status-not_answered .result-states__task:hover {
  border-color: #DFDFDF;
}
.result-states__content {
  display: flex;
  align-items: center;
}
.result-states__task {
  padding: 0;
}
.result-states__task .chips__text {
  font-size: 16px;
  margin: 0 auto;
}
.result-states__section {
  text-align: left;
  font-weight: 500;
  margin-top: 8px;
}
.result-states__title {
  display: flex;
  align-items: start;
}
.result-states__title-icon {
  margin-top: 4px;
}
