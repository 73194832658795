.task-client-validation-label {
  align-items: center;
}
.task-client-validation-label-level-error,
.theme-light .task-client-validation-label-level-error .ui-icon {
  color: #DF222E;
}
.task-client-validation-label-level-warning,
.theme-light .task-client-validation-label-level-warning .ui-icon {
  color: #E76A01;
}
.task-client-validation-label .ui-icon {
  align-self: flex-start;
}
