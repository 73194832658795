.task_page {
  min-height: 100%;
}
.task_page .task__section {
  overflow: visible;
  padding-bottom: 100px;
}
.single_task.type_programming {
  padding-bottom: 0px;
}
.single_task.type_programming .theme-light .dropdown-select {
  background-color: transparent;
}
.single_task.type_programming .theme-light .dropdown-select:hover {
  background-color: rgba(0, 0, 0, 0.08);
}
.t-task-layout.task {
  display: flex;
  flex-direction: column;
}
.t-task-layout.task .single_task {
  padding-top: 25px;
  padding-bottom: 25px;
}
